<div class="tuula-table-container">
  <div style="display: flex; flex-direction: column">
    <div class="tuula-table-top-bar" [ngStyle]="{'border-bottom': customTitleElement ? 0 : 1} ">
      <div class="tuula-table-title-container">
      <span class="tuula-table-title">
        {{ title | translate }}
      </span>
      </div>
      <div class="tuula-table-controls">
        <div class="tuula-table-controls-sub-container">
          <ng-container *ngTemplateOutlet="tableControls">
          </ng-container>
        </div>
        <div class="tuula-table-controls-sub-container">
          <div class="h-col-sub">
            <button class="tuula-table-filter-button" (click)="onToggleFilter()">
              <img src="/assets/icons/filter2.svg" alt="" />
              <span class="tuula-table-filter-button-text">{{'notification.labels.Filters' | translate}}</span>
              <div class="arrow">
                <img src="/assets/icons/arrow-down2.svg" alt="" *ngIf="!filterPanelOpened" />
                <img src="/assets/icons/arrow-up2.svg" alt="" *ngIf="filterPanelOpened" />
              </div>
            </button>
          </div>
          <button *ngIf="tableSettingsVisible" (click)="onOpenTableSettings()" class="tuula-table-control-button">
            <mat-icon>settings</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="tuula-table-title-template" *ngIf="customTitleElement">
      <ng-container *ngTemplateOutlet="customTitleElement">
      </ng-container>
    </div>
  </div>
    <mat-table [dataSource]="dataSource" class="tuula-table"
               matSort (matSortChange)="onSortChanged($event)" cdkDropList
               cdkDropListOrientation="horizontal"
               (cdkDropListDropped)="drop($event)">
      @for (column of columns; track column) {
        <ng-container [matColumnDef]="column.prop" [sticky]="column.frozenLeft" [stickyEnd]="column.frozenRight">
          <mat-header-cell cdkDrag cdkDragLockAxis="x" [cdkDragDisabled]="column.draggable === false" *matHeaderCellDef
                           mat-sort-header [disabled]="column.sortable === false"
                           [ngStyle]="{ 'flex-grow': 1, 'flex-shrink': 0, 'flex-basis.px': column.width ? column.width : defaultCellWidth, 'justify-content': column.justify ? column.justify :'start' }"
          >
            @if (column.headerTemplate) {
              <ng-container
                *ngTemplateOutlet="column.headerTemplate; context: { column: column }">
              </ng-container>
            } @else {
              <span>{{ column.name | translate }}</span>
            }
          </mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index"
                    [ngStyle]="{ 'flex-grow': 1, 'flex-shrink': 0, 'flex-basis.px': column.width ? column.width : defaultCellWidth, 'justify-content': column.justify ? column.justify :'start' }"
          >
            @if (column.cellTemplate) {
              <ng-container
                *ngTemplateOutlet="column.cellTemplate; context: { row: row, rowIndex: i, rowValue: getNestedValue(row, column) }">
              </ng-container>
            } @else {
              <span
                [matTooltip]="getNestedValue(row, column)">
                {{ getNestedValue(row, column) }}
            </span>
            }
          </mat-cell>
        </ng-container>

          <!-- Filter Columns -->
        <ng-container matColumnDef="{{column.prop + filterColumnNamePostfix}}" [sticky]="column.frozenLeft" [stickyEnd]="column.frozenRight">
          <mat-header-cell *matHeaderCellDef
          [ngStyle]="{ 'flex-grow': 1, 'flex-shrink': 0, 'flex-basis.px': column.width ? column.width : defaultCellWidth, 'justify-content': column.justify ? column.justify :'start' }">
            @if (filterPanelOpened){
                <app-tuula-table-filter-header-column style="width:100%" [column]="column" (filterChanged)="onApplyFilter($event)" (filterCleared)="onClearFilter()"></app-tuula-table-filter-header-column>
            }
          </mat-header-cell>
        </ng-container>
      }
      <mat-header-row *matHeaderRowDef="tableDisplayedColumns; sticky: stickyMode" [ngStyle]="{ 'min-width.px': totalRowWidth }"></mat-header-row>
      <mat-header-row class="{{filterPanelOpened ? '' : 'invisible-header-row'}}" *matHeaderRowDef="tableDisplayedFilterColumns, sticky: stickyMode" [ngStyle]="{ 'min-width.px': totalRowWidth }" ></mat-header-row>
      <mat-row *matRowDef="let row; columns: tableDisplayedColumns;" [ngClass]="[tableRowSize === 'default'? 'tuula-table-row-default' : 'tuula-table-row-compact']"
               [ngStyle]="{ 'min-width.px': totalRowWidth }" ></mat-row>

    </mat-table>
  <mat-paginator [pageSizeOptions]="pageSizeOptions"
                 [showFirstLastButtons]="false"
                 [length]="pagination?.totalDocs"
                 (page)="onPageChanged($event)"
                 [pageSize]="pagination?.pageSize"
                 [pageIndex]="pagination?.page - 1"
  >
  </mat-paginator>
</div>

