import { ChangePasswordComponent } from '../change-password/change-password.component';
import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RolePickerComponent } from '../role-picker/role-picker.component';
import { KeycloakService } from 'keycloak-angular';
import { PermissionService } from '../../services';
import {UserInfoModel, UserInfoService} from '../../services';
import { ServiceDeskComponent } from '../service-desk-form/service-desk.component';
import {Observable, Subject, takeUntil} from 'rxjs';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit, OnDestroy {
  @Output() public roleChanged = new EventEmitter();
  private readonly destroyed$ = new Subject<boolean>();
  userInfo: UserInfoModel;
  menuActions = [
    {
      icon: 'person',
      tooltip: 'general.labels.MyProfile',
      method: this.navigateToProfile.bind(this),
      visible: true,
    },
    {
      icon: 'sync_alt',
      tooltip: 'general.labels.ChangeFunction',
      method: this.roleDialog.bind(this),
      visible: true,
    },
    {
      icon: 'contact_support',
      tooltip: 'general.labels.SendUsYourFeedBack',
      method: this.navigateToServiceDesk.bind(this),
      visible: true,
    },
    // hide for go live
    // { icon: 'format_color_fill', tooltip: 'Theme', method: this.themeEdit.bind(this) },
    {
      icon: 'power_settings_new',
      tooltip: 'general.labels.Logout',
      method: this.logout.bind(this),
      visible: true,
    },
  ];
  clientPortalMenuActions = [
    {
      icon: 'lock',
      tooltip: 'general.labels.ChangePassword',
      method: this.changePasswordDialog.bind(this),
      visible: true,
    },
    {
      icon: 'contact_support',
      tooltip: 'general.labels.SendUsYourFeedBack',
      method: this.navigateToServiceDesk.bind(this),
      visible: true,
    },
    {
      icon: 'power_settings_new',
      tooltip: 'general.labels.Logout',
      method: this.logout.bind(this),
      visible: true,
    },
  ];
  customStyleForAvatar: boolean;

  constructor(
    @Inject('environment') private environment,
    private matDialog: MatDialog,
    private keycloakService: KeycloakService,
    private permissionsService: PermissionService,
    public userInfoService: UserInfoService,
    private permissionService: PermissionService,
    public dialog: MatDialog,
    private router: Router,
  ) {}


  ngOnInit(): void {
    this.userInfoService.userInfo$
    .pipe(
      takeUntil(this.destroyed$),
    ).subscribe(userInfo => {
      this.userInfo = userInfo;
    })
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  get currentRole$(): Observable<string> {
    return this.permissionService.currentRole$;
  }

  navigateToDashboard() {
    this.router.navigate(['/']);
  }

  navigateToServiceDesk() {
    const isEmployeePortal = this.userInfoService?.pageInfoService?.isEmployeePortal;
    const isClientPortal = this.userInfoService?.isClientPortal;

    if (this.environment.serviceDeskUrl !== '') {
      const serviceDeskUrl = this.environment.serviceDeskUrl;
      window.open(serviceDeskUrl, '_blank');
    }
    else {
    const dialogRef = this.matDialog.open(ServiceDeskComponent, {
        data: {
          employeePortal: isEmployeePortal,
          clientPortal: isClientPortal,
        },
      });
  }
  }

  changePasswordDialog() {
    const dialogRef = this.matDialog.open(ChangePasswordComponent, {
      data: null,
    });
  }

  roleDialog() {
    const dialogRef = this.matDialog.open(RolePickerComponent, {
      data: null,
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.roleChanged.emit();
        location.reload();
      }
    });
  }

  logout() {
    this.keycloakService.logout(this.getBaseUrl());
    this.permissionsService.logout();
  }

  private getBaseUrl() {
    const getUrl = window.location;
    const baseUrl = getUrl.protocol + '//' + getUrl.host + '/';

    return baseUrl;
  }
  truncateText(text: string, maxLength: number) {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength-3) + '...';
    } else {
      return text;
    }
  }

  navigateToProfile() {
    this.router.navigate(
      [`/employees/${this.userInfoService.employeeId}/employee-personal`],
      { queryParams: { type: 'selfEdit' } },
    );
  }

  filterName(fullName: string | undefined) {
    fullName = fullName ?? '';
    const cleanedFullName = fullName.replace(/-/g, '');
    const initials = cleanedFullName.split(' ').map(word => word[0]).join('');
    if(initials.length > 4) this.customStyleForAvatar = true
    return cleanedFullName;
  }
}
