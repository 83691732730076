@if (column.enableFiltering){
  @if (column.filterTemplate) {
    <ng-container style="width:100%" *ngTemplateOutlet="column.filterTemplate; context: { column: column }"></ng-container>
  } @else {

    <div style="width: 100%" *ngIf="column">
      @if (column?.filter?.type === 'text' || column?.filter?.type === 'number') {
        <mat-form-field appearance="outline" class="table-header-filter-form-input-field">
          <input matInput [formControl]="valueControl">
        </mat-form-field>
      } @else if (column?.filter?.type === 'date' || column?.filter?.type === 'dateTime') {
        @if (column?.filter?.interval) {
          <mat-form-field appearance="outline" class="table-header-filter-form-input-field">
            <mat-date-range-input [formGroup]="dateIntervalGroup" [rangePicker]="rangepicker">
              <input matStartDate [formControl]="startDateControl">
              <input matEndDate [formControl]="endDateControl">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="rangepicker"></mat-datepicker-toggle>
            <mat-date-range-picker #rangepicker></mat-date-range-picker>
          </mat-form-field>
        } @else {
          <mat-form-field appearance="outline" class="table-header-filter-form-input-field">
            <input matInput [formControl]="valueControl" [matDatepicker]="datepicker" placeholder="Select a date" (click)="datepicker.open()">
            <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
            <mat-datepicker #datepicker></mat-datepicker>
          </mat-form-field>
        }
      } @else if (column?.filter?.type === 'time') {
        <mat-form-field appearance="outline" class="table-header-filter-form-field" [matMenuTriggerFor]="timeMenu">
          <input matInput [formControl]="valueControl" (input)=formatTimeInput($event) (keydown)=allowOnlyTimeInput($event)>
          <button mat-icon-button>
            <mat-icon style="display: flex; align-items: center; justify-content: flex-end" class="c4p-action-icons">access_time</mat-icon>
          </button>
        </mat-form-field>
      }
      @else if (column?.filter?.type === 'select' || column?.filter?.type === 'boolean') {
        <mat-form-field appearance="outline" class="table-header-filter-form-field">

          <mat-select [formControl]="valueControl" [multiple]="column?.filter?.multiSelection" [panelWidth]="null">

            @if (column?.filter?.loadOptions) {
              <mat-option>
                <ngx-mat-select-search
                  [formControl]="searchControl"
                  [clearSearchInput]="false"
                  [searching]="isSearchResultLoading"
                  placeholderLabel="{{ 'general.actions.Search' | translate }}..."
                  noEntriesFoundLabel="{{ 'general.labels.Empty' | translate }}">
                </ngx-mat-select-search>
              </mat-option>
            }

            @if (!column?.filter?.multiSelection) {
              <mat-option></mat-option>
            }

            @for (option of column?.filter?.options; track option) {
              <mat-option [value]="option[column.filter.optionValueProperty]">{{option[column.filter.optionLabelProperty] | translate}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      }
    </div>
  }
} @else if (column.prop === 'menuActions') {
    <button mat-icon-button class="reset-filters-btn" (click)="onClearFilters()">
      <mat-icon>refresh</mat-icon>
      <span class="text">Reset Filters</span>
    </button>
}

<mat-menu #timeMenu>
  <div class="table-header-filter-timepicker" (click)="$event.stopPropagation();">
    <ngx-timepicker-field [format]="24" controlOnly="true" (timeChanged)="onChangeHour($event)" [formControl]="valueControl">
    </ngx-timepicker-field>
  </div>
</mat-menu>

