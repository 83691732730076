import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { SideSheetService } from '../../../services';
import { ActionType, SIDE_SHEET_CONFIG, SIDE_SHEET_CONTENT, SideSheetButtonEvent, SideSheetButtonListener, SideSheetConfig, SideSheetContent, TableColumn } from '../../../models';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-tuula-table-settings',
  templateUrl: './tuula-table-settings.html',
  styleUrl: './tuula-table-settings.scss'
})
export class TuulaTableSettingsComponent implements OnInit, OnDestroy, SideSheetButtonListener {


  private readonly destroyed$ = new Subject<boolean>();
  columns: TableColumn[] = [];
  leftmostColumns: TableColumn[] = [];
  rightmostColumns: TableColumn[] = [];

  tableRowSize: string;
  stickyHeader = true;

  constructor(
    private sideSheetService: SideSheetService,
    @Inject(SIDE_SHEET_CONTENT) public sideSheetContent: SideSheetContent,
    @Inject(SIDE_SHEET_CONFIG) public sideSheetConfig: SideSheetConfig,
  ) { }

  ngOnInit(): void {
    this?.sideSheetContent?.data?.columns?.forEach(col => {
      if (col.permanent && col.frozenRight === true) {
          this.rightmostColumns.push(col);
      } else if (col.permanent && col.frozenLeft === true) {
        this.leftmostColumns.push(col);
      } else {
        this.columns.push(col);
      }
    });
    this.tableRowSize = this?.sideSheetContent?.data?.tableRowSize;
    this.stickyHeader = this?.sideSheetContent?.data?.stickyMode;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onToggleColumn(column) {
    column.hidden = !column.hidden;
  }

  onPinColumn(column) {
    column.frozenLeft = !column.frozenLeft;
  }

  onTableRowSizeChange(event) {
    this.tableRowSize = event;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
  }

  onClickButton(event: SideSheetButtonEvent) {
    if (event?.button?.actionType === ActionType.SIDE_SHEET_CLOSE) {
      this.sideSheetService.closeSideSheet();
    }

    if (event?.button?.actionType === ActionType.SAVE) {
      this.sideSheetService.closeComponent({columns: [...this.leftmostColumns, ...this.columns, ...this.rightmostColumns], tableRowSize: this.tableRowSize, stickyMode: this.stickyHeader});
    }
  }

  onStickyHeaderChange(event) {
    this.stickyHeader = event;
  }

}
